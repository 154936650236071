var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { checkIfTheSellersFieldShows } from '@/shared/utils/helpers';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import Alert from '../Alert.vue';
import { connect } from '@/overmind';
let SignUpForm = class SignUpForm extends Vue {
    constructor() {
        super(...arguments);
        this.btnLoading = false;
        this.disableFields = true;
        this.errorField = '';
        this.data = {
            name: '',
            email: '',
            cellphone: '',
            segment: '',
            segment_menu: false,
            segment_items: [
                'B2B (Empresas)',
                'B2C (Pessoa física)',
                'Ambos (Empresas e pessoa física)',
                'Não faço prospecção',
            ],
            sdrs: '',
            sdrs_menu: false,
            sdrs_items: ['Nenhum', '1', '2 a 3', '4 a 8', '9 a 15', '15+'],
            sellers: '',
            sellers_menu: false,
            sellers_items: ['1', '2 a 3', '4 a 8', '9 a 15', '15+'],
        };
        this.oldData = {
            name: '',
            email: '',
            cellphone: '',
            segment: '',
            segment_menu: false,
            segment_items: [
                'B2B (Empresas)',
                'B2C (Pessoa física)',
                'Ambos (Empresas e pessoa física)',
                'Não faço prospecção',
            ],
            sdrs: '',
            sdrs_menu: false,
            sdrs_items: ['Nenhum', '1', '2 a 3', '4 a 8', '9 a 15', '15+'],
            sellers: '',
            sellers_menu: false,
            sellers_items: ['1', '2 a 3', '4 a 8', '9 a 15', '15+'],
        };
        this.errorList = {
            emptyFields: {
                message: '<b>Ops!</b> Preencha todos os campos.',
            },
            hasAuth: {
                message: '<b>Usuário já cadastado!</b> Tente com outros dados ou faça login para continuar ',
            },
            general: {
                message: '<b>Ops!</b> Ocorreu um erro, por favor tente novamente.',
            },
        };
    }
    resetError() {
        const { cellphone: phone, email, segment, sdrs, sellers } = this.data;
        if (checkIfTheSellersFieldShows(sdrs, segment)) {
            this.disableFields = !(phone && email && sellers);
            if (!this.disableFields)
                return (this.errorField = '');
            return;
        }
        this.disableFields = !(phone && email && segment && sdrs);
        if (!this.disableFields)
            return (this.errorField = '');
    }
    resetFields() {
        for (let each in this.data) {
            this.oldData[each] = this.data[each];
            this.data[each] = '';
        }
    }
    selectSegment(item) {
        this.data.segment = item;
        this.data.segment_menu = false;
    }
    selectSdrs(item) {
        this.data.sdrs = item;
        this.data.sdrs_menu = false;
    }
    selectSellers(item) {
        this.data.sellers = item;
        this.data.sellers_menu = false;
    }
    async handleSignUp() {
        if (this.btnLoading)
            return;
        this.btnLoading = true;
        if (checkIfTheSellersFieldShows(this.data.sdrs, this.data.segment) &&
            this.data.sellers.trim() == '') {
            this.resetFields();
            return this.setErrorField('emptyFields');
        }
        if (!this.data.email ||
            !this.data.cellphone ||
            !this.data.segment ||
            !this.data.sdrs) {
            this.resetFields();
            return this.setErrorField('emptyFields');
        }
        this.btnLoading = true;
        return this.emitUserData();
    }
    verifyIfSelectIsOpen(selectValue) {
        if (selectValue)
            return 'opened';
        return '';
    }
    verifyIfIsEmpty(variable, placeholder) {
        if (variable.trim() == '')
            return placeholder;
        return variable;
    }
    customErrorText(errors) {
        const errorArr = errors;
        let errorText = errorArr['title'];
        errorText += `<br /><b>${errorArr['description'].split(':')[1]}</b>`;
        this.errorList['customErros'] = { message: errorText };
    }
    emitUserData() {
        return this.data;
    }
    emitOldData() {
        return this.oldData;
    }
    setErrorField(field) {
        this.btnLoading = false;
        return (this.errorField = field);
    }
};
__decorate([
    Prop({ default: {} })
], SignUpForm.prototype, "plgData", void 0);
__decorate([
    Watch('data', { deep: true })
], SignUpForm.prototype, "resetError", null);
__decorate([
    Emit('data')
], SignUpForm.prototype, "emitUserData", null);
__decorate([
    Emit('oldData')
], SignUpForm.prototype, "emitOldData", null);
__decorate([
    Watch('errorKey')
], SignUpForm.prototype, "setErrorField", null);
SignUpForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'SignUpForm',
        components: {
            Alert,
            IconSpeedioSpinner,
        },
    }))
], SignUpForm);
export default SignUpForm;
